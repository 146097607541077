<template>
  <div>

    <!-- banner start -->
    <section class="banner_sec media_page">
      <div class="banner_hed">
        <h1 class="heading_2">News & Views</h1>
      </div>
    </section>
    <!-- banner end -->

    <!-- news start -->
    <section>
      <vue-sticky-sidebar class="sidebar " resizeSensor="false" containerSelector=".container" topSpacing="110"
        bottomSpacing="0" innerWrapperSelector='.sidebar__inner'>
        <div class="news_sidebar">
          <ul class="container">
            <li><a v-scroll-to="{el: '#news',easing: 'linear',offset: -110,}">Media Matters (news) </a></li>
            <li><a v-scroll-to="{el: '#events',easing: 'linear',offset: -110,}" >Events  </a></li>
            <li><a v-scroll-to="{el: '#webinars',easing: 'linear',offset: -110,}" >Past Presentations </a></li>
          </ul>
        </div>
      </vue-sticky-sidebar>

      <div id="content" class="w-100">
        <div class="news_sec padding_80" id="news">
          <div class="container">
            <div class="row justify-content-end">
              <div class="col-md-12">
                <div class="news_head">
                  <h3 class="heading_3">Media Matters</h3>
                </div>
              </div>
            </div>
            <div class="row justify-content-end">             
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="grid-item">
                      <a href="https://www.courant.com/business/hc-biz-work-from-home-injuries-20210906-cmrxw3zikba6nbcsaghtfgrz5e-story.html" target="_blank">
                      <img src="../../assets/home/images/media/n1.png" alt="img" />
                      <p>Worker’s Comp Cases During the Pandemic</p>
                      </a>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="grid-item">
                      <a href="https://www.cnn.com/2020/12/08/success/manager-remote-work-transformed/index.html" target="_blank">
                      <img src="../../assets/home/images/media/n2.png" alt="img" />
                      <p>
                        Work <br />
                        Transformed
                      </p>
                      </a>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="grid-item">
                      <a href="https://www.wsj.com/articles/working-from-home-is-taking-a-toll-on-our-backs-and-necks-11589398420" target="_blank">
                      <img src="../../assets/home/images/media/n3.png" alt="img" />
                      <p>Work from Home Is Taking A Toll On Our Backs and Necks</p>
                      </a>
                    </div>
                  </div>

                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="grid-item">
                      <a href="https://www.kqed.org/forum/2010101877697/tips-for-working-from-home-without-ruining-your-neck-and-back" target="_blank">
                      <img src="../../assets/home/images/media/n4.png" alt="img" />
                      <p>Work from Home Without Ruining Your Back</p>
                      </a>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="grid-item">
                      <a href="https://states.aarp.org/minnesota/working-from-home-during-covid-19" target="_blank">
                      <img src="../../assets/home/images/media/n5.png" alt="img" />
                      <p>
                        Work from Home During <br />
                        Covid-19
                      </p>
                      </a>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="grid-item living-proof-logo">
                      <a href="https://www.livingproofadvocacy.com/blog/2020/6/9/advocate-of-the-month-vivienne-fleischer" target="_blank">
                      <img src="../../assets/home/images/media/n6.png" alt="img" />
                      <p>PBE’s Co-Founders Journey To Self-Care</p>
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <!-- news end -->
        <!-- were available start -->
        <div class="empower_sec were_box py_50 bg_primary">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="empower_box text-center">
                  <h5 class="heading_5 mb_30 text_white font_bold">
                    We’re available for interviews and guest appearances
                  </h5>
                  <a href="learn-more" class="btn btn_outline nxtbtn">Let’s Do This!</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- were available end -->
            
        <!-- events start -->
        <div class="event_sec padding_80 bg_grey" id="events">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="news_head">
                  <h3 class="heading_3">Podcasts, Conferences and More</h3>
                </div>
              </div>
            </div>
            <div class="row justify-content-end">             
              <div class="col-md-12">
                <div class="events_box mb-0">
                 <p class="podcast_new">New!</p> 
                  <h6 class="heading_6 mb-0">Podcasts: Check out PBE's podcast appearances!</h6> <!--mb-0-->
                  <!-- <h6 class="heading_6 text-center">Coming Soon <br> <br></h6> -->
                  <div class="d-flex past_event_box">
                    <iframe title="Embed Player" src="https://play.libsyn.com/embed/destination/id/4438473/height/412/theme/modern/size/large/thumbnail/yes/custom-color/7c2ae8/playlist-height/200/direction/backward/font-color/ffffff" height="400" width="100%" scrolling="no" allowfullscreen="" webkitallowfullscreen="true" mozallowfullscreen="true" oallowfullscreen="true" msallowfullscreen="true" style="border: none;border-radius:12px"></iframe>
                  </div>
                  <div class="d-flex past_event_box">
                    <iframe style="border-radius:12px" src="https://open.spotify.com/embed/episode/4X2BvhTD2z3q5ak5kDa9gA?utm_source=generator" width="100%" height="400" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                  </div>
                 
                  <div class="d-flex past_event_box">
                    
                  </div>
                  
                </div>

                <div class="events_box">
                  <h6 class="heading_6 past-events pas-ev">Events</h6>

                  <div class="d-flex past_event_box">
                    <div class="flex-shrink-0">
                      <img src="../../assets/home/images/media/nationalergo_logo.png" alt="National Ergo Expo Conference" style="height:auto;" />
                    </div>
                    <div class="flex-grow-1 ml_110">
                      <h5>
                        National Ergo Expo Conference
                      </h5>
                      <p>October 15-17, 2024 </p>
                    </div>
                  </div>

                  <div class="d-flex past_event_box">
                    <div class="flex-shrink-0">
                      <img src="../../assets/home/images/media/sept_event_banner.png" alt="Ergo Lunch-N-Learn" style="height:auto;" />
                    </div>
                    <div class="flex-grow-1 ml_110">
                      <h5>
                       Ergo Lunch-N-Learn: Improve Your Ergonomics Program Through Technology and Data
                      </h5>
                      <p>September 26, 2024 </p>
                      <p>11:30 AM - 1:00 PM</p>
                    </div>
                  </div>

                  <div class="d-flex past_event_box">
                    <div class="flex-shrink-0">
                      <img src="../../assets/home/images/media/applied_eco.png" alt="img" style="height:auto;" />
                    </div>
                    <div class="flex-grow-1 ml_110">
                      <h5>
                        Applied Ergonomics Conference
                      </h5>
                      <p>March 25-28, 2024 </p>
                    </div>
                  </div>

                   <div class="d-flex past_event_box">
                    <div class="flex-shrink-0">
                      <img src="../../assets/home/images/media/american.png" alt="img" />
                    </div>
                    <div class="flex-grow-1 ml_110">
                      <h5>
                        Ergonomics Live Summit <br> Navigating Hybrid Working
                      </h5>
                      <p>March 4-6, 2022 </p>
                    </div>
                  </div>

                  <div class="d-flex past_event_box ">
                    <div class="flex-shrink-0">
                      <img src="../../assets/home/images/media/national.png" alt="img" />
                    </div>
                    <div class="flex-grow-1 ml_110">
                      <h5>
                        Back To the Future With Twitch & PBE A Work from Home Success Story
                      </h5>
                      <p>November 3, 2021 </p>
                    </div>
                  </div>

                  <div class="d-flex past_event_box">
                    <div class="flex-shrink-0">
                      <img src="../../assets/home/images/media/national.png" alt="img" />
                    </div>
                    <div class="flex-grow-1 ml_110">
                      <h5>
                        Analyzing, Digitizing and Globalizing Your Ergonomics
                        Program. A case study with Airbnb & PBE
                      </h5>
                      <p>August 22, 2019</p>
                    </div>
                  </div>

                  <div class="d-flex past_event_box">
                    <div class="flex-shrink-0">
                      <img src="../../assets/home/images/media/cal.png" alt="img" />
                    </div>
                    <div class="flex-grow-1 ml_110">
                      <h5>
                        Sitting, Standing, Working. Everything You Need To Know.
                      </h5>
                      <p>July 22, 2017</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- events end -->

        <!-- events start -->
        <div class="webinars_news_sec padding_80" id="webinars">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="news_head">
                  <h3 class="heading_3">Past Presentations</h3>
                </div>
              </div>
            </div>
            <div class="row justify-content-center">             
              <div class="col-md-10">
                <div class="row">

                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="news_webinars_box">
                     <a href="#" data-bs-toggle="modal" data-bs-target="#chris3" class="text_black">  <img src="../../assets/home/images/media/w2.png" alt="img" /> </a>
                      <p>
                       Navigating Hybrid Work
                        <span>- 3/4/22</span>
                      </p>
                    </div>
                  </div>

                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="news_webinars_box">
                     <a href="#" data-bs-toggle="modal" data-bs-target="#chris2" class="text_black">  <img src="../../assets/home/images/media/w1.png" alt="img" /> </a>
                      <p>
                        Where’s My Office Today? <br />PBE & One Workplace
                        <span>- 9/23/21</span>
                      </p>
                    </div>
                  </div>                  

                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4">
                    <div class="news_webinars_box">
                     <a href="https://register.gotowebinar.com/recording/114981843719070991" target="_blank"> <img src="../../assets/home/images/media/w7.png" alt="img" /> </a>
                      <p>
                        Returning Back To the Office? <br />Are You Ergo prepared?
                        <span>- 5/26/21</span>
                      </p>
                    </div>
                  </div>
                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4 pt_100">
                    <div class="news_webinars_box">
                      <a href="https://register.gotowebinar.com/recording/4572868170743789064" target="_blank"> <img src="../../assets/home/images/media/w4.png" alt="img" /> </a>
                      <p>
                        Parenting While Working From <br />Home, A Panel Discussion
                        <span> - 10/16/20 </span>
                      </p>
                    </div>
                  </div>

                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4 pt_100">
                    <div class="news_webinars_box">
                         <a href="#" data-bs-toggle="modal" data-bs-target="#chris" class="text_black">
                         <img src="../../assets/home/images/media/w5.png" alt="img" /></a>
                      <p>
                        Work from Home Stipends <br />A Panel Discussion
                        <span>- 9/10/20</span>
                      </p>
                    </div>
                  </div>

                  <div class="col-sm-6 col-md-6 col-lg-6 col-xl-4 pt_100">
                    <div class="news_webinars_box">
                 
                      <a href="https://blogs.poly.com/how-to-avoid-the-phenomenon-of-video-fatigue/" target="_blank"><img src="../../assets/home/images/media/w6.png" alt="img" /></a>
                      <p>
                        How To Minimize Video Fatigue <br />When Work from Home
                        <span>- 5/18/20</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- events end -->
      </div>
    </section>

    <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade meet_our_team_modal" id="chris" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="hideModal"></button>
          </div>
          <div class="modal-body">
              <div style="padding:56.25% 0 0 0;position:relative;"><iframe id="closed" src="https://player.vimeo.com/video/456753795?h=06ce12343c&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="WFH Stipend Webinar"></iframe></div>
          </div>
        </div>
      </div>
    </div>
    <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade meet_our_team_modal" id="chris1" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="hideModal1"></button>
          </div>
          <div class="modal-body">
              <div style="padding:56.25% 0 0 0;position:relative;"><iframe id="closed1" src="https://player.vimeo.com/video/506252597?h=920b14623a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="WFH Stipend Webinar"></iframe></div>
          </div>
        </div>
      </div>
    </div>
 <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade meet_our_team_modal" id="chris2" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="hideModal2"></button>
          </div>
          <div class="modal-body">
              <div style="padding:56.25% 0 0 0;position:relative;"><iframe id="closed2" src="https://player.vimeo.com/video/619221080?h=d134e90547&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="WFH Stipend Webinar"></iframe></div>
          </div>
        </div>
      </div>
    </div>

     <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade meet_our_team_modal" id="chris3" tabindex="-1" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content ">
          <div class="modal-header border-bottom-0">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="hideModal3"></button>
          </div>
          <div class="modal-body">
              <div style="padding:56.25% 0 0 0;position:relative;"><iframe id="closed3" src="https://player.vimeo.com/video/682992714?h=67a7ad6a5b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Virtual Ergo Summit - Hybrid Working"></iframe></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import vueStickySidebar from "vue-sticky-sidebar";
  export default {
    name: "media",
    components: {
      "vue-sticky-sidebar": vueStickySidebar
    },
     methods: {
        hideModal() { 
          document.getElementById('closed').src += '';
      },
      mounted(){
        document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
      },
      hideModal1() { 
          document.getElementById('closed1').src += '';
      },
       hideModal2() { 
          document.getElementById('closed2').src += '';
      },
      hideModal3() { 
          document.getElementById('closed3').src += '';
      },
     }
  };
</script>